// Only code specific to this site should be here. Anything that can be used across multiple sites
// should be pushed into the KMS.

// Using a bundler like webpack
import GLightbox from "glightbox";
import {FloatLabels} from "@keodesign/float-labels";

export function init() {
	GLightbox({
		touchNavigation: true,
		loop: true,
		autoplayVideos: true
	});
	setupMapClick();
	new FloatLabels(".js-float-wrap");
}

export function setupMapClick(){
	const map_click_list = document.querySelectorAll("#map-click, .map-click");
	if (map_click_list) {
		map_click_list.forEach((map_click) => {
			map_click.addEventListener("click", () => {
				// shield the map on mobile for easy scrolling
				map_click.style.display = "none";
			});
		});
	}
}
window.addEventListener("DOMContentLoaded", init);